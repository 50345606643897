import React, { useContext, useEffect, useState } from "react";
import {
  BtnClearFilter,
  BtnSFilter,
  ColumnContainer,
  ContainerFilterBar,
  GroupInput,
  ImgDefault,
  ImgOpt,
  InputDefault,
  RowContainer,
  SelectDefault,
  TextDefault,
} from "../../../assets/styles";
import country from "../utils/flag";
import { message } from "antd";

export default function FilterLeads({ ...props }) {
  const [listType, setListType] = useState("");
  const [countrySel, setCountrySel] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const getFlagCountry = (cty) => {
    if (cty) {
      const flag = country.filter((obj) => obj.label === cty);
      return flag[0].src;
    }
    return require("../../../flag/world.png");
  };

  const clearInputs = () => {
    setListType("");
    setCountrySel("");
    setCompanyName("");
    props.getFinder(false);
  };

  const handleList = (event) => {
    setListType(event.target.value);
    props.getListType(event.target.value);
  };

  const handleCountrySelect = (event) => {
    const selectedCountry = event.target.value;

    setCountrySel(selectedCountry);

    props.getCountry(selectedCountry);
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    props.getFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
    props.getLastName(event.target.value);
  };

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
    props.getCompanyName(event.target.value);
  };

  const searchData = () => {
    if (listType === "company") {
      if (!companyName || !countrySel) {
        let errorMessage = "Please fill in the following fields:";
        if (!companyName) errorMessage += " Company Name -";
        if (!countrySel) errorMessage += " Country";

        message.error(errorMessage);
      } else {
        props.getFinder(true);
      }
    } else if (listType === "contact") {
      if (!companyName || !countrySel || !firstName || !lastName) {
        let errorMessage = "Please fill in the following fields:";
        if (!companyName) errorMessage += " Company Name";
        if (!firstName || !lastName) errorMessage += " First or Last name";
        if (!countrySel) errorMessage += " Country";

        message.error(errorMessage);
        return;
      } else {
        props.getFinderContact(true);
      }
    }
  };

  return (
    <ContainerFilterBar>
      <ColumnContainer style={{ paddingLeft: 100 }}>
        <TextDefault size="38px" color="#4b4b4b" bold="800">
          Filters
        </TextDefault>
        <TextDefault
          size="12px"
          color="#8a97aa"
          bold="700"
          style={{ marginTop: 10 }}
        >
          Pass the parameters according to the company you are looking for
        </TextDefault>
      </ColumnContainer>

      <ColumnContainer
        style={{
          paddingLeft: 85,
          paddingRight: 20,
          marginTop: 20,
          width: "100%",
          height: "70%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <TextDefault
          size="12px"
          color="#8a97aa"
          bold="700"
          style={{ marginTop: 10 }}
        >
          Search Type*
        </TextDefault>
        <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
          <SelectDefault
            className={"inputSale   selectSale"}
            type="text"
            style={{ color: "#94a3b8" }}
            value={listType}
            onChange={handleList}
          >
            <option value="" disabled selected>
              Company, Contact or My List
            </option>
            <option value={"company"}>Search Company</option>
            <option value={"contact"}>Search Contact</option>
            <option value={"myList"}>My List</option>
          </SelectDefault>

          <ImgOpt
            className="iconInputHide"
            src={require("../../../icons/down-arrow 2.png")}
          />
        </GroupInput>

        <TextDefault
          size="12px"
          color="#8a97aa"
          bold="700"
          style={{ marginTop: 25 }}
        >
          Country*
        </TextDefault>
        <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
          <ImgOpt
            className="iconInputSale"
            src={getFlagCountry(countrySel)}
            style={{ width: 35, height: 35, opacity: 0.8 }}
          />
          <SelectDefault
            className={"inputSale   selectSale"}
            type="text"
            style={{ color: "#94a3b8", paddingLeft: 60 }}
            value={countrySel}
            onChange={handleCountrySelect}
          >
            <option value="" disabled selected>
              Country
            </option>

            {country.map((item, index) => (
              <option value={item.label}>{item.label}</option>
            ))}
          </SelectDefault>

          <ImgOpt
            className="iconInputHide"
            src={require("../../../icons/down-arrow 2.png")}
          />
        </GroupInput>

        <TextDefault
          size="12px"
          color="#8a97aa"
          bold="700"
          style={{ marginTop: 25 }}
        >
          Company Name*
        </TextDefault>
        <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
          <InputDefault
            className={"inputSale   selectSale"}
            placeholder="Company Name"
            type="text"
            style={{ color: "#94a3b8" }}
            value={companyName}
            onChange={handleCompanyName}
          />
        </GroupInput>

        {listType == "contact" ? (
          <>
            <TextDefault
              size="12px"
              color="#8a97aa"
              bold="700"
              style={{ marginTop: 25 }}
            >
              First Name*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale   selectSale"}
                placeholder="Fist Name"
                type="text"
                style={{ color: "#94a3b8" }}
                value={firstName}
                onChange={handleFirstName}
              />
            </GroupInput>
            <TextDefault
              size="12px"
              color="#8a97aa"
              bold="700"
              style={{ marginTop: 25 }}
            >
              Last Name*
            </TextDefault>
            <GroupInput className="groupInputSale" style={{ marginTop: 5 }}>
              <InputDefault
                className={"inputSale   selectSale"}
                placeholder="Last Name"
                type="text"
                style={{ color: "#94a3b8" }}
                value={lastName}
                onChange={handleLastName}
              />
            </GroupInput>
          </>
        ) : (
          ""
        )}
      </ColumnContainer>
      <RowContainer
        style={{
          width: "100%",
          paddingLeft: 85,
          bottom: 20,
          boxShadow: "0px -20px 35px rgba(0, 0, 0, 0.05)",
        }}
      >
        <BtnClearFilter onClick={clearInputs}>
          <ImgDefault
            src={require("../../../icons/clean 1.png")}
            width="30px"
            height="30px"
          />
        </BtnClearFilter>

        <BtnSFilter onClick={searchData}>
       
            <TextDefault size="25px" color="#fff" bold="500">
              Search
            </TextDefault>
       
        </BtnSFilter>
      </RowContainer>
    </ContainerFilterBar>
  );
}
