import React, { useEffect, useRef, useState } from "react";
import { AddNewCard, CardHsList, CardSelected, ColumnContainer, ContainerModalHs, ImgClose, ImgDefault, ModalPainel, RowContainer, TagHsCode, TextDefault } from "../../../../assets/styles";
import { Modal } from "@mui/material";
import SearchInput from "../../../home/components/SearchInput";
import Capitalize from "../../../../utils/capitalize";
import HsCodeList, { excludeFavoritedHs, favoritedHs, getFavoritedHs } from "../../../../hooks/HsCodeList";
import { FixedSizeList as List } from "react-window";

export default function HsCodeSelected({...props}) {

    const [isModal, setIsModal] = useState(false)
    const [search, setSearch] = useState("");
    const [hsCodeSelected, setHsCodeSelected] = useState([])
    const [hsCodeAll, setHsCodeAll] = useState([])
    const [hsCodeM, setHsCodeM] = useState([])
    const [favoriteList, setFavoriteList] = useState([])
    const [favoriteAtt, setFavoriteAtt] = useState(1)
    const [limitFavorite, setLimitFavorite] = useState(true)
 
    useEffect(() => {
        if(props.hsCodeM) {
            setHsCodeSelected([])
            props.setHsCodeM(false)
        }
    }, [props.hsCodeM])
    
    const ExcludeHs = (key) => {
        const exclude = hsCodeSelected.filter((obj) => {return obj.hsCode !== key})
        setHsCodeSelected(exclude)
        props.getHsCode(exclude)
    }
    
    const closeModal = () => {
        setIsModal(false)
    }
    
    
    const AddHsCode = (hsCode, hsName) => {
        if(hsCodeSelected.length < 5 && !validSelect(hsCode)) {
            props.getHsCode([...hsCodeSelected, {hsCode, hsName}])
            setHsCodeSelected([...hsCodeSelected, {hsCode, hsName}])
            setIsModal(false)
            setSearch("")
        }
    }

    
    const validSelect = (key) => {
        return hsCodeSelected.some(obj => obj.hsCode === key)
    }

    const validFavorited = (key) => {
        return favoriteList.some(obj => obj.hsCode === key)
    }

    const addMoreHsCode = () => {
        if(hsCodeAll.length == 0) {
            setTimeout(() => {
                HsCodeList().then(resp => {
                    setHsCodeAll(resp)
                    setHsCodeM(resp)
                })
            }, 1000)
        }

        setIsModal(true)
    }

    useEffect(() => {
        if(hsCodeAll) {
            setHsCodeM(hsCodeAll.filter(obj => obj.hsCode.toLowerCase().includes(search.toLowerCase()) || obj.hsName.toLowerCase().includes(search.toLowerCase())));
        }
    }, [search])

    useEffect(() => {
        getFavoritedHs().then(resp => {
            console.log(resp)
            setFavoriteList(resp)
            setLimitFavorite(resp.length)
            console.log(resp.length)
        })
        .catch(e => {
            console.log(e)
        }) 
    }, [favoriteAtt])

    const playsStarred = (code, name) => {
        if(validFavorited(code)) {
            excludeFavoritedHs(code)            
            setFavoriteAtt(favoriteAtt+1)
            if(favoriteList.length == 1) {
                setFavoriteList([])
            }

        } else {
            favoritedHs(code, name)
            setFavoriteAtt(favoriteAtt+1)
        }
    }

    const handleFocus = () => {
        if(favoriteList.length > 3) {
            setLimitFavorite(false)
        }
    };

    function extrairNumeros(texto) {
        // utiliza expressão regular para encontrar apenas os números na string
        let numerosEncontrados = texto.match(/\d+/g);
        
        // verifica se foram encontrados números
        if (numerosEncontrados !== null) {
          // retorna os números encontrados como um array
          return numerosEncontrados.toString();
        } else {
          // caso não encontre nenhum número, retorna um array vazio
          return [];
        }
      }
      

    return(
        <>
            <Modal
                open={isModal}
                onClose={() => closeModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                 <ContainerModalHs>
                    <ModalPainel>
                        <RowContainer style={{width: "100%", justifyContent: "flex-end", paddingRight: 20}}>
                            <ImgClose onClick={closeModal} src={require("../../../../icons/CloseG1.png")} width="14px" height="14px"/>
                        </RowContainer>

                        <RowContainer style={{width: "97%", marginBottom: 10}}>
                            <SearchInput
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}                                
                                onFocus={handleFocus}
                            />
                        </RowContainer>

                        {hsCodeAll.length == 0 ? (
                            <RowContainer style={{height: "70%", marginLeft: 20, width: "94%", justifyContent: "center", alignItems: "center"}}>
                                <div class="loaderVV">
                                    <span class="loaderVV-text">loading</span>
                                    <span class="loadVV"></span>
                                </div>
                            </RowContainer>                    
                        ) : (

                            <ColumnContainer style={{overflow: "auto", height: "80%", width: "100%"}}>
                                
                                {favoriteList ? (
                                    <>
                                        <TextDefault color="#4b4b4b" size="25px" bold="800" style={{marginLeft: 8, marginBottom: 10}}>Favorite</TextDefault>

                                        {limitFavorite ? favoriteList.map((item, index) => (
                                            <ColumnContainer style={{paddingLeft: 8}} key={index}>
                                                <CardHsList selected={validSelect(item.hsCode)}>
                                                    <RowContainer style={{width: "100%", height: "100%", alignItems: "center", paddingLeft: 30}} onClick={() => AddHsCode(item.hsCode, item.hsName)}>
                                                        <TagHsCode selected={validSelect(item.hsCode)}>
                                                            <TextDefault size="14px" color="#fff">{item.hsCode}</TextDefault>
                                                        </TagHsCode>
            
                                                        <TextDefault className="lineLM2" color="#4b4b4b" style={{marginLeft: 30, width: "89%", paddingRight: 40}}>{Capitalize(item.hsName)}</TextDefault>                                                
                                                    </RowContainer>

                                                    <ImgDefault
                                                    onClick={() => playsStarred(item.hsCode, item.hsName)}
                                                    style={{cursor: "pointer"}}
                                                    src={validFavorited(item.hsCode) ? require("../../../../icons/favorite-on.png") : require("../../../../icons/favorite-off.png")}
                                                    width="19px"
                                                    height="19px"
                                                    />
            
                                                </CardHsList>
                                            </ColumnContainer>
                                        )) : (

                                        <ColumnContainer style={{paddingLeft: 8}}>
                                            <CardHsList onClick={() => setLimitFavorite(true)}>
                                                <RowContainer style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                    <TextDefault color="#026AA2" size="15px">View all favorites</TextDefault>
                                                </RowContainer>
                                            </CardHsList>
                                        </ColumnContainer>
                                        )}


                                        <ColumnContainer style={{paddingLeft: 8}}>
                                            <RowContainer style={{width: "96%", height: 1.5, backgroundColor: "#f4f4f4"}}/>                                    
                                        </ColumnContainer>
                                    </>
                                ) : ""}                                

                                <TextDefault color="#4b4b4b" size="25px" bold="800" style={{marginLeft: 8}}>All HS Codes</TextDefault>
                                {hsCodeM.length > 0 ? (
                                  <List
                                    height={600}
                                    itemCount={hsCodeM.length} 
                                    itemSize={80}
                                    width="100%" 
                                  >
                                    {({ index, style }) => {
                                      const item = hsCodeM[index];
                                      return (
                                        <ColumnContainer style={{ paddingLeft: 8, ...style }} key={index}>
                                          <CardHsList selected={validSelect(item.hsCode)}>
                                            <RowContainer
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                alignItems: "center",
                                                paddingLeft: 30,
                                              }}
                                              onClick={() => AddHsCode(item.hsCode, item.hsName)}
                                            >
                                              <TagHsCode selected={validSelect(item.hsCode)}>
                                                <TextDefault size="14px" color="#fff">
                                                  {item.hsCode}
                                                </TextDefault>
                                              </TagHsCode>

                                              <TextDefault
                                                className="lineLM2"
                                                color="#4b4b4b"
                                                style={{ marginLeft: 30, width: "89%", paddingRight: 40 }}
                                              >
                                                {Capitalize(item.hsName)}
                                              </TextDefault>
                                            </RowContainer>

                                            <ImgDefault
                                              onClick={() => playsStarred(item.hsCode, item.hsName)}
                                              style={{ cursor: "pointer" }}
                                              src={
                                                validFavorited(item.hsCode)
                                                  ? require("../../../../icons/favorite-on.png")
                                                  : require("../../../../icons/favorite-off.png")
                                              }
                                              width="19px"
                                              height="19px"
                                            />
                                          </CardHsList>
                                        </ColumnContainer>
                                      );
                                      }}
                                  </List>
                                ) : (
                                    <>
                                        {extrairNumeros(search).length > 0 ? (

                                            <ColumnContainer style={{paddingLeft: 8}}>
                                                <CardHsList>
                                                    <RowContainer style={{width: "100%", height: "100%", alignItems: "center", paddingLeft: 30}} onClick={() => AddHsCode(extrairNumeros(search), "HS Code Custom")}>
                                                        <TagHsCode style={{backgroundColor: "#E53069"}}>
                                                            <TextDefault size="14px" color="#fff">{extrairNumeros(search)}</TextDefault>
                                                        </TagHsCode>

                                                        <TextDefault className="lineLM2" color="#4b4b4b" style={{marginLeft: 30, width: "89%", paddingRight: 40}}>Add this HS Code manually - HS Code Custom</TextDefault>                                                
                                                    </RowContainer>

                                                    <ImgDefault
                                                    src={require("../../../../icons/wrench.png")}
                                                    width="19px"
                                                    height="19px"
                                                    />

                                                </CardHsList>
                                            </ColumnContainer>
                                        ) : (
                                            <RowContainer style={{width: "96.5%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                                <ImgDefault src={require("../../../../icons/empty-box.png")} width="130px" height="130px" />
                                            </RowContainer>
                                        )}
                                    </>
                                )}

                            </ColumnContainer>
                        )}



                    </ModalPainel>
                 </ContainerModalHs>

            </Modal>
            <ColumnContainer style={{marginTop: 20}}>


                <TextDefault color="#8a97aa" size="11px" style={{marginBottom: 8}}>Hs Code*</TextDefault>

                {hsCodeSelected ? hsCodeSelected.map((item) => (
                    <RowContainer style={{alignItems: "center", marginBottom: 20}} key={item.key}>
                        <CardSelected>
                            <TextDefault className="lineLM1" size="13px" style={{width: "75%"}} color="#8a97aa">{item.hsCode} - {Capitalize(item.hsName)}</TextDefault>
                                <div class="tooltip-container">
                                    <span class="tooltip">{Capitalize(item.hsName)}</span>
                                    <ImgDefault src={require("../../../../icons/document.png")} width="20px" height="20px"/>
                                </div>

                        </CardSelected>
                        <ImgClose onClick={() => ExcludeHs(item.hsCode)} src={require("../../../../icons/CloseG1.png")} width="14px" height="14px" style={{marginLeft: 10}}/>
                    </RowContainer>
                )) : ""}


                {props.listType != "un" && hsCodeSelected.length < 5 || props.listType == "un" && hsCodeSelected.length < 1 ? (
                    <AddNewCard onClick={() => addMoreHsCode()}>
                        <TextDefault color="#8a97aa" size="13px" bold="700" style={{opacity: 0.5}}>Add Hs Code</TextDefault>
                        <ImgDefault src={require("../../../../icons/add.png")} style={{opacity: 0.5}} width="17px" height="17px"/>
                    </AddNewCard>
                ) : ""}
            </ColumnContainer>
        </>
    )
}