import React from "react";
import ReactApexChart from "react-apexcharts";
import formatToCurrency from "../../../../../utils/formatToCurrency";
import Country from "../../../../../components/Flag";
import { formatNumberKg } from "../../../../../utils/numberFormat";

const ChartValueCountry = ({ data }) => {
  const getCountry = (id) => {
    if (id) {
      if (id == "Global Search") {
        return "World";
      }
      const flag = Country.filter((obj) => obj.id === id);

      if (flag.length > 0) {
        return flag[0].country;
      }
      return id;
    }

    return "World";
  };

  const top10Data = [...data]
    .sort((a, b) => (b.primaryValue || 0) - (a.primaryValue || 0))
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.partnerCode === item.partnerCode)
    )
    .slice(0, 10)
    .map((item) => ({
      ...item,
      primaryValue:
        item.primaryValue !== undefined && item.primaryValue !== null
          ? item.primaryValue
          : 0,
      country: getCountry(item.partnerCode),
    }));

  const chartData = {
    categories: top10Data.map((item) => getCountry(item.partnerCode)),
    series: [
      {
        name: "Primary Value",
        data: top10Data.map((item) => item.primaryValue),
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      distributed: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "By Value",
      },
    },
    yaxis: {
      title: {
        text: "Primary Value",
      },
    },
    tooltip: {
      y: {
        formatter: (value) => formatToCurrency(value),
      },
    },
    colors: [
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#3F51B5",
      "#03A9F4",
      "#4CAF50",
      "#F9CE1D",
      "#FF9800",
      "#33B2DF",
    ],
  };

  const top10DataVolume = [...data]
    .sort((a, b) => (b.netWgt || 0) - (a.netWgt || 0))
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.partnerCode === item.partnerCode)
    )
    .slice(0, 10)
    .map((item) => ({
      ...item,
      netWgt:
        item.netWgt !== undefined && item.netWgt !== null ? item.netWgt : 0,
      country: getCountry(item.partnerCode),
    }));

  const chartDataVolume = {
    categories: top10DataVolume.map((item) => getCountry(item.partnerCode)),
    series: [
      {
        name: "Net Weight (Kg)",
        data: top10DataVolume.map((item) => item.netWgt),
      },
    ],
  };

  const chartOptionsVolume = {
    chart: {
      type: "bar",
      height: 350,
      distributed: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    xaxis: {
      categories: chartDataVolume.categories,
      title: {
        text: "By Volume",
      },
    },
    yaxis: {
      title: {
        text: "Net Weight (Kg)",
      },
    },
    tooltip: {
      y: {
        formatter: (value) => formatNumberKg(value),
      },
    },
    colors: [      
     "#33B2DF",     
    ],
  };

  return (
    <>
      <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        height={350}
      />

      <ReactApexChart
        options={chartOptionsVolume}
        series={chartDataVolume.series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default ChartValueCountry;
